<template>
  <div class="common-page" :class="{ flexEnd: hiddenTotal }">
    <div v-show="!hiddenTotal && total > 0">
      <span>共{{ Math.ceil(total / pageSize) }}页，</span>
      <span>共{{ total }}条记录</span>
    </div>
    <el-pagination v-if="total > 0" :total="total" :pager-count="5" :page-size="pageSize" layout="prev, pager, next"
      :current-page="currentPage" @current-change="handleCurrentChange"> </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    total: Number,
    pageSize: {
      type: Number,
      // default: 3
      default: 10
    },
    hiddenTotal: Boolean,
    currentPage: Number
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.common-page {
  height: 32px;
  display: flex;
  margin: 0 22px;
  color: #9b9d9e;
  line-height: 32px;
  justify-content: space-between;

  &.flexEnd {
    justify-content: flex-end;
  }
}

.el-pagination {
  padding: 0;
}

.el-pagination::v-deep button {
  padding: 0;
  min-width: 10px;
  color: #a8a9aa;
  line-height: 32px;
  background: transparent;
}

.el-pagination::v-deep .el-icon {
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
}

.el-pagination::v-deep .btn-next {
  color: #fff;
}

.el-pagination::v-deep .btn-pre:hover,
.el-pagination::v-deep .btn-next:hover {
  color: #fff;
}

.el-pagination::v-deep .el-pager li {
  height: 32px;
  color: #fff;
  min-width: 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  margin-right: 12px;
  background: transparent;
}

.el-pagination::v-deep .el-pager li:first-child {
  margin-left: 12px;
}

.el-pagination::v-deep .el-pager li:not(.disabled):hover {
  // border-radius: 50%;
  color: #fff;
}

.el-pagination::v-deep .el-pager li:not(.disabled).active {
  border-radius: 50%;
  background-color: #2667db;
}
</style>
