<template>
  <div>
    <el-form class="el-form-custom" :model="formData" :inline="true">
      <el-form-item label="监测类型">
        <el-select v-model="formData.typeGroupId" placeholder="请选择" @change="getTargetOptions">
          <el-option v-for="item in typeOptions" :key="item.id" :value="item.id" :label="item.name" />
        </el-select>
      </el-form-item>
      <el-form-item label="监测指标">
        <el-select v-model="formData.typeId" placeholder="请选择">
          <el-option v-for="item in targetOptions" :key="item.id" :value="item.id" :label="item.name" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="formData.code" placeholder="请输入测点编号" />
      </el-form-item>
      <el-form-item>
        <el-button @click="getData('query')">查 询</el-button>
        <el-button @click="resetData">重 置</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName">
      <el-table-column align="center" label="测点编号" prop="code"></el-table-column>
      <el-table-column align="center" label="监测类型" prop="typeName"></el-table-column>
      <el-table-column align="center" label="监测指标" prop="typeGroupName"></el-table-column>
      <el-table-column align="center" label="位置描述" prop="site"></el-table-column>
      <el-table-column align="center" label="标度界限">
        <template slot-scope="scope">
          <span>{{ scope.row.duration != 0 ? "0.8，1.0，1.1" : "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <span :class="!myOption ? 'disable_icon' : ''" class="operate-txt" @click="setThreshold(scope.row.id)"> 设置阈值 </span>
        </template>
      </el-table-column>
    </el-table>
    <common-pagination :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
  </div>
</template>

<script>
import CommonPagination from "@/components/Pagination";
export default {
  components: {
    CommonPagination
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      disabled: false,
      formData: { typeGroupId: "", typeId: "" },
      typeOptions: [],
      targetOptions: [],
      tableData: []
    };
  },
  mounted() {
    // if(sessionStorage.getItem('isAdmin') == 1) {
    this.disabled = sessionStorage.getItem("isTrue") == "true" ? false : true;
    this.projectId = sessionStorage.getItem("projectId");
    // } else {
    // this.disabled = true
    // }
    this.getPointTypes();
    this.getData();
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    resetData() {
      this.formData = { typeGroupId: "", typeId: "" };
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
    setThreshold(id) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      if (this.disabled) return;
      this.$router.push("/setDegeneration/" + id);
    },
    getPointTypes() {
      this.$axios.get(`${this.baseURL}base/baseTargetTypeGroup/`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          res.data.unshift({ id: "", name: "全部" });
          this.typeOptions = res.data;
          this.getTargetOptions();
        }
      });
    },
    getTargetOptions() {
      this.$axios
        .get(`${this.baseURL}base/baseTargetType/byGroupId`, {
          typeGroupId: this.formData.typeId
        })
        .then((res) => {
          res = res.data;
          if (res.errCode == 200) {
            res.data.unshift({ id: "", name: "全部" });
            this.targetOptions = res.data;
          }
        });
    },
    getData(val) {
      if (val) this.currentPage = 1;
      this.$axios
        .get(`${this.baseURL}setttings/settingsThresholdDegradation/${this.currentPage}/10/${this.projectId}`, {
          params: this.formData
        })
        .then((res) => {
          res = res.data;
          if (res.errCode == 200) {
            this.total = res.data.total;
            let arr = [];
            res.data.records.forEach((e) => {
              e.target.duration = e.duration;
              arr.push(e.target);
            });
            this.tableData = arr;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-table {
  margin-bottom: 35px;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
